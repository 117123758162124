<template>
  <div>
    <div class="d-flex flex-row-reverse">
      <b-button size="sm" v-if="deep == 0" @click="expand_all">
        <span @click="changeVisible">
          {{ btnExpandLabel }}
          <b-icon class="expander" :icon="iconButtonLabel" />
        </span>
      </b-button>
    </div>
    <b-card v-for="(item, key) in structure" :key="key" class="mb-2 p-0 mt-2">
      <div
        class="d-flex align-items-center tree-item cursor-pointer"
        :ref="formatIdCollapse(item.id)"
        @click="collapseRow(item.id)"
      >
        <span class="flex-grow-1">
          <label class="form-label text-muted">{{ level_name }}</label>
          <h5 class="mb-0">{{ item.name }}</h5>
        </span>

        <b-badge
          v-if="childrenLength(item)"
          class="mr-2 bg-children-color"
          v-b-tooltip.hover
          title="Diretrizes"
          >{{ childrenLength(item) }}
        </b-badge>
        <b-badge
          v-if="objectivesLength(item)"
          class="mr-2 bg-objectives-color"
          v-b-tooltip.hover
          title="Objetivos"
          >{{ objectivesLength(item) }}</b-badge
        >

        <b-badge
          v-if="measuresLength(item)"
          class="mr-2 bg-measures-color"
          v-b-tooltip.hover
          title="Metas"
          >{{ measuresLength(item) }}</b-badge
        >

        <b-badge
          v-if="indicatorsLength(item)"
          class="mr-2 bg-indicators-color"
          v-b-tooltip.hover
          title="Indicadores"
          >{{ indicatorsLength(item) }}</b-badge
        >

        <b-badge
          v-if="actionPlansLength(item)"
          class="mr-2 bg-warning text-dark"
          v-b-tooltip.hover
          title="Planos de ações"
        >
          {{ actionPlansLength(item) }}
        </b-badge>

        <b-icon
          v-if="item.children.length > 0 || objectives(item.id).length"
          class="expander"
          :icon="getIconToCollapse(formatIdCollapse(item.id))"
          style="width: 20px; height: 20px"
        ></b-icon>
      </div>

      <b-collapse v-model="collapses[formatIdCollapse(item.id)]" class="mt-2">
        <b-badge
          v-if="item.children.length > 1"
          @click="$bvModal.show('tree-item-draggable-diretriz' + item.id)"
          class="mb-2 text-order"
          variant="light"
          >Ordenar
        </b-badge>

        <EstruturaChild
          ref="structureChild"
          :showBagde="false"
          :structure="item.children"
          :deep="next_deep"
        />

        <b-badge
          v-if="objectives(item.id) && objectives(item.id).length"
          @click="$bvModal.show('tree-item-draggable' + item.id)"
          class="mb-2 text-order"
          variant="light"
          >Ordenar</b-badge
        >

        <ObjetivosTable
          v-if="objectives(item.id).length"
          :objectives="objectives(item.id)"
        />
      </b-collapse>

      <ModalDraggable
        v-if="objectives(item.id).length"
        title="Ordernar Objetivos"
        :items="objectives(item.id)"
        :id="'tree-item-draggable' + item.id"
        @order="orderObjectivesById($event, item.id)"
      />

      <ModalDraggable
        v-if="item.children && item.children.length > 1"
        title="Ordernar Diretrizes"
        :items="item.children"
        :id="'tree-item-draggable-diretriz' + item.id"
        @order="orderDiretrizesById($event, item.strategic_plan_id)"
      />
    </b-card>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

import ObjetivosTable from "../components/Forms/ObjetivosTable";
import ModalDraggable from "@/components/modal/ModalDraggable";
import { LocalStorage } from "@/helpers/functions/localStorage";

const defaultKeyStored = "@pam/opened-item";
export default {
  components: {
    EstruturaChild: () => import("./Estrutura"),
    ObjetivosTable: ObjetivosTable,
    ModalDraggable
  },
  data() {
    return {
      expanded: false,
      collapse_structure: false,
      showbadge: true,
      visible: false,
      collapses: {},
      hasScrolled: false
    };
  },
  props: {
    structure: {
      type: Array
    },
    deep: {
      type: Number,
      default: 0
    },
    showBagde: {
      type: [String, Boolean],
      default: "tooltip"
    },
    showCollapseButton: {
      type: [String, Boolean],
      default: "collapse"
    },
    color: {
      type: [String, Boolean],
      default: "primary"
    }
  },
  computed: {
    ...mapGetters("strategic_plans", [
      "getTreePlanStructureLength",
      "getMeasuresLength",
      "getIndicatorsLength"
    ]),
    ...mapState("strategic_plans", ["strategic_plan", "plan_objectives"]),
    allItemsOpened() {
      const verifyCollapse = key => !!this.collapses[key];
      const allItemsState = Object.keys(this.collapses).every(verifyCollapse);
      return allItemsState;
    },
    btnExpandLabel() {
      if (!this.allItemsOpened) return "Expandir todos";
      return this.expanded ? "Recolher todos" : "Expandir todos";
    },
    iconButtonLabel() {
      if (!this.allItemsOpened) return "arrow-down-circle";
      return this.expanded ? "arrow-up-circle" : "arrow-down-circle";
    },
    levels() {
      return this.strategic_plan.config.plan_structure.levels;
    },
    level_name() {
      return this.levels[this.deep];
    },
    next_deep() {
      return this.deep + 1;
    },
    getIdFromStructure() {
      let string = this.structure.map(item => {
        var children = item.children.map(element => {
          return `tree-item-${element.id}`;
        });

        let newArray = [];
        newArray.push(`tree-item-${item.id}`);
        var arr = [...newArray, ...children];
        return arr;
      });

      return string.flat();
    },
    currentItem: {
      get() {
        return LocalStorage(defaultKeyStored).getItem;
      },
      set(value) {
        if (!this.hasScrolled) {
          return;
        }
        LocalStorage(defaultKeyStored).store(value);
      }
    }
  },
  methods: {
    ...mapActions("strategic_plans", [
      "ActionOrderObjectivesById",
      "ActionOrderDiretrizById",
      "ActionLoadPlanStructure",
      "ActionGetObjectives"
    ]),
    changeVisible() {
      this.visible = !this.visible;
    },

    getIconToCollapse(id) {
      const direction = this.collapses[id] ? "up" : "down";
      return `arrow-${direction}-circle`;
    },

    formatIdCollapse(id) {
      return `tree_item_${id}-${this.deep}`;
    },

    getTotalItems(total, current) {
      return total + current
    },

    objectives(id) {
      return _.filter(this.plan_objectives, ["plan_structure_id", id]);
    },
    measures(id) {
      const arrayObjectives = this.objectives(id);
      const arrayMeasures = arrayObjectives.map(item => item.measures.length);

      if (arrayMeasures.length > 0) {
        return arrayMeasures.reduce(this.getTotalItems, 0);
      }
      return 0;
    },
    indicators(id) {
      const arrayObjectives = this.objectives(id);
      const arrayIndicators = arrayObjectives.map(item =>
        item.indicators.length
      );

      if (arrayIndicators.length > 0) {
        return arrayIndicators.reduce(this.getTotalItems, 0);
      }
      return 0;
    },
    actionPlansLength(item) {
      const listObjectives = this.objectives(item.id);

      if (listObjectives.length > 0) {
        function sumTotalByKey(key) {
          return (acc, curr) => {
            return curr ? acc + curr[key] : 0
          };
        }

        return listObjectives.reduce(sumTotalByKey("plans_total"), 0);
      }

      return 0;
    },
    childrenLength(item) {
      var countChildren = item => {
        var response = item.children.length;

        item.children.forEach(child => {
          response += countChildren(child);
        });

        return response;
      };

      return countChildren(item);
    },
    objectivesLength(item) {
      var countChildren = item => {
        var response = this.objectives(item.id).length;

        item.children.forEach(child => {
          response += countChildren(child);
        });

        return response;
      };

      return countChildren(item);
    },
    measuresLength(item) {
      const countChildren = item => {
        let response = this.measures(item.id);
        item.children.forEach(child => {
          response += countChildren(child);
        });

        return response;
      };

      return countChildren(item);
    },

    indicatorsLength(item) {
      const countChildren = item => {
        let response = this.indicators(item.id);

        item.children.forEach(child => {
          response += countChildren(child);
        });

        return response;
      };

      return countChildren(item);
    },
    collapseRow(id = "") {
      const formatedId = this.formatIdCollapse(id);
      this.$set(this.collapses, formatedId, !this.collapses[formatedId]);
      this.addParamsToScroll(formatedId, this.collapses[formatedId]);
    },
    clearParamsToScroll() {
      this.currentItem = [];
      LocalStorage(defaultKeyStored).store([]);
    },
    addParamsToScroll(formatedId = "", open) {
      if (!LocalStorage(defaultKeyStored).has) {
        this.clearParamsToScroll();
      }
      const storedItems = LocalStorage(defaultKeyStored).getItem || [];
      const findById = id => formatedId.includes(id);

      const indexItem = storedItems.findIndex(findById);
      const splitedDeep = formatedId.split("-")[1] || "0";
      const deepValue = parseInt(splitedDeep);

      const hasStoredParams = storedItems.length > 0;
      const isFirstCollapse = deepValue === 0;
      const hasFilteredItem = indexItem > -1;
      const firstItemHasClosed = isFirstCollapse && !open;
      const hasFirstItem = storedItems[0];

      if (hasFilteredItem && isFirstCollapse && hasStoredParams) {
        storedItems.splice(0, storedItems.length);
      }

      if (firstItemHasClosed || (!hasFirstItem && !isFirstCollapse)) {
        this.currentItem = [];
        return;
      }

      storedItems[deepValue] = formatedId;
      this.currentItem = storedItems;
    },

    expand_all() {
      this.expanded = !this.expanded;
      for (const key in this.collapses) {
        this.collapses[key] = this.expanded;
      }
      if (this.expanded) {
        this.clearParamsToScroll();
      }
      this.collapse_structure = true;

      return this.getIdFromStructure;
    },
    findElement($refs, item) {
      const elements = $refs[item] || [];
      return elements[0];
    },
    restoreScrollPosition() {
      if (!LocalStorage(defaultKeyStored).has) {
        return;
      }

      const itemIsArray = _.isArray(this.currentItem);
      const collapses = itemIsArray ? this.currentItem : [];

      const firstElementId = collapses[0];
      const $refs = this.$refs;

      function scrollToElement(element) {
        const position = element.getBoundingClientRect();
        const top = Math.ceil(position.y + window.scrollY);
        if (top === window.scrollY) {
          return;
        }

        window.scrollTo({ top, behavior: "smooth" });
      }

      for (const collapseId of collapses) {
        this.collapses[collapseId] = true;
      }

      const element = this.findElement($refs, firstElementId);

      if (element) {
        scrollToElement(element);
        element.setAttribute("data-highlight", "");
      }
      this.hasScrolled = true;
    },
    async orderObjectivesById($event, id) {
      try {
        let payload = {
          id,
          objectives: $event
        };

        await this.ActionOrderObjectivesById(payload);
        this.$notifications.addNotification({
          message: "Ordem alterada com sucesso",
          type: "success"
        });
        await this.ActionGetObjectives(this.strategic_plan.id);
      } catch (error) {
        let error_message = "Erro ao salvar";
        this.$notifications.addNotification({
          message: error_message,
          icon: "exclamation-triangle",
          type: "danger"
        });
      }
    },
    async orderDiretrizesById($event, id) {
      try {
        let payload = {
          id,
          diretrizes: $event
        };

        await this.ActionOrderDiretrizById(payload);
        this.$notifications.addNotification({
          message: "Ordem alterada com sucesso",
          type: "success"
        });
        await this.ActionLoadPlanStructure();
      } catch (error) {
        let error_message = "Erro ao salvar";
        this.$notifications.addNotification({
          message: error_message,
          icon: "exclamation-triangle",
          type: "danger"
        });
      }
    }
  },
  mounted() {
    this.hasScrolled = false;
    this.$nextTick(function() {
      this.restoreScrollPosition();
      setTimeout(() => {
        this.clearParamsToScroll();
      }, 0);
    });
  }
};
</script>

<style>
.expander {
  fill: #ccc;
  outline: none !important;
}

.tree-item {
  outline: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.tree-item:hover .expander {
  fill: #335080;
  cursor: pointer;
}

.position {
  position: absolute;
  top: 35px;
  right: 28px;
}

.bg-children-color {
  background: #335080;
}

.bg-objectives-color {
  background: #03914f;
}

.bg-measures-color {
  background: #fe6d31;
}

.bg-indicators-color {
  background: #028fb5;
}

.text-order {
  color: #000;
  font-weight: normal;
  cursor: pointer;
  display: block;
  max-width: 60px;
  margin-left: auto;
  border: 1px solid transparent;
}

.text-order:hover {
  color: #335080;
  font-weight: normal;
  cursor: pointer;
  border: 1px solid #335080;
}
</style>
