<template>
  <div>
    <div class="mb-4">
      <b-form-checkbox v-model="strategic_plan.active">
        Plano {{ strategic_plan.active ? "Ativo" : "Inativo" }}
      </b-form-checkbox>
    </div>
    <b-form-group label="Nome:">
      <b-form-input
        v-model="strategic_plan.name"
        placeholder="Nome"
        :state="validateState('name')"
        
      ></b-form-input>
      <b-form-invalid-feedback>
        O campo Nome é obrigatório.
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label="Responsável:">
      <Responsible v-model="strategic_plan.responsible_id" />
    </b-form-group>

    <b-form-group label="Estrutura Organizacional:">
      <StructureSelect v-model="strategic_plan.organizational_structure_id" />
    </b-form-group>

    <b-form-group label="Período:">
      <b-row>
        <b-col>
          <date-picker
            v-model="strategic_plan.start_term"
            placeholder="Início"
          />
        </b-col>
        <b-col>
          <date-picker v-model="strategic_plan.end_term" placeholder="Fim" />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group label="Instrumento Legal:">
      <b-row>
        <b-col>
          <b-form-input
            v-model="strategic_plan.legal_instrument"
            placeholder="Lei"
          ></b-form-input>
        </b-col>
        <b-col>
          <date-picker v-model="strategic_plan.legal_instrument_date" />
        </b-col>
        <b-col cols="6">
          <b-form-input
            v-model="strategic_plan.legal_instrument_link"
            placeholder="Link para o instrumento"
          ></b-form-input>
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group label="Descrição:">
      <b-form-textarea
        v-model="strategic_plan.description"
        placeholder="Descrição"
        rows="4"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<script>
import Responsible from "@/modules/physical_people/components/Responsible";
import StructureSelect from "@/modules/organizational_structures/components/StructureSelect";
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  components: { Responsible, StructureSelect },
  props: {},
  data: () => ({}),
  mixins: [validationMixin],
  validations: {
    strategic_plan: {
      name: {
        required
      }
    }
  },

  computed: {
    ...mapState("strategic_plans", ["strategic_plan"])
  },
  methods: {
     validateState(name) {
      const { $dirty, $error } = this.$v.strategic_plan[name];
      return $dirty ? !$error : null;
    },
    isValid() {
      this.$v.strategic_plan.$touch();
      return !this.$v.strategic_plan.$anyError;
    }
  },
  created() {}
};
</script>
