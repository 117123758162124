<template>
  <div class="card border-0 radius-bottom-0" v-if="strategic_plan">
    <div class="card-header bg-dark text-white row m-0 pt-4 pb-4">
      <div class="col-5">
        <h4 class="card-tite d-flex align-items-center">
          <font-awesome-icon
            v-if="plan_icon"
            :icon="plan_icon"
            class="display-4 mr-3"
          />
          <span class="">{{
            strategic_plan.name ? strategic_plan.name : "Novo Plano Estratégico"
          }}</span>
        </h4>
        <div class="mt-4">
          <p class="text-muted" v-if="strategic_plan.description">
            {{ strategic_plan.description.substring(0, 180) + "..." }}
          </p>
        </div>
      </div>
      <div class="col-3 semafaro-plano">
        <div class="ml-3 mt-3">
          <label class="form-label">Responsável</label>
          <p class="text-muted" v-if="strategic_plan.responsible">
            {{ strategic_plan.responsible.name }} /
            {{ strategic_plan.organizationalStructure.name }}
          </p>
          <p class="text-muted" v-else>Defina um responsável</p>
        </div>
        <div class="ml-3 mt-3">
          <label class="form-label">Instrumento Legal</label>
          <p class="text-muted d-flex align-items-center">
            {{ strategic_plan.legal_instrument }} /
            {{ strategic_plan.legal_instrument_date | format_date("YYYY") }}
            <a
              class="ml-2"
              target="_blank"
              :href="strategic_plan.legal_instrument_link"
              ><span class="material-icons">open_in_new</span></a
            >
          </p>
        </div>
      </div>
      <div class="col-4">
        <div class="ml-3 mt-3">
          <label class="form-label">Vigência</label>
          <p class="text-muted">
            {{ strategic_plan.start_term | format_date }} a
            {{ strategic_plan.end_term | format_date }}
            <br /><br />
          </p>
        </div>

        <div class="ml-3 mt-3">
          <label class="form-label">Última atualização</label>
          <p class="text-muted">
            {{ strategic_plan.updated_at | format_date }}
          </p>
        </div>
        <div class="link-edit" v-if="show_edit">
          <router-link
            v-if="$can('strategic_plan:edit')"
            :to="{
              name: 'strategic_plans_edit',
              params: { id: strategic_plan.id },
            }"
            class="btn btn-primary mr-2"
            >Editar</router-link
          >
          <b-button @click="deletePlan" class="btn btn-danger"
            >Excluir
          </b-button>
        </div>
      </div>
      <traffic-light class="semafaro" :status="statusPlan" />
    </div>
    <div class="card-body plano-item-tabs" v-if="tabs">
      <div class="row">
        <div class="col-3" @click="setTabSelect('structure')">
          <div class="card" :class="{ active: tabSelected === 'structure' }">
            <div class="card-body">
              <h6 class="card-subtitle text-muted">Estrutura</h6>
              <h5 class="card-title m-0">{{ getTreePlanStructureLength }}</h5>
            </div>
          </div>
        </div>
        <div class="col-3" @click="setTabSelect('objectives')">
          <div class="card" :class="{ active: tabSelected === 'objectives' }">
            <div class="card-body">
              <h6 class="card-subtitle text-muted">Objetivos</h6>
              <h5 class="card-title m-0">{{ plan_objectives.length }}</h5>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card">
            <div class="card-body">
              <h6 class="card-subtitle text-muted">Indicadores e Metas</h6>
              <h5 class="card-title m-0">
                {{ count_indicadores }} / {{ count_metas }}
              </h5>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card">
            <div class="card-body">
              <h6 class="card-subtitle text-muted">Planos e Ações</h6>
              <h5 class="card-title m-0">
                {{ count_actions_plans }} / {{ count_actions_plans_itens }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import TrafficLight from "@/components/TrafficLight/TrafficLight.vue";

export default {
  components: {
    TrafficLight,
  },
  data: () => ({
    tabSelected: "",
  }),
  props: {
    tabs: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState("strategic_plans", ["strategic_plan", "plan_objectives"]),
    ...mapGetters("strategic_plans", ["getTreePlanStructureLength"]),
    show_edit() {
      return this.$route.name != "strategic_plans_edit";
    },
    statusPlan() {
      return this.strategic_plan.config.plan_structure.status;
    },
    plan_icon() {
      if (this.strategic_plan.icon) {
        var icon = this.strategic_plan.icon.split(" ");
        if (icon.length > 1) {
          return icon;
        } else {
          return this.strategic_plan.icon;
        }
      }
      return false;
    },
    count_indicadores() {
      var indicadores = 0;
      this.plan_objectives.forEach((objective) => {
        indicadores += objective.indicators_total;
      });
      return indicadores;
    },
    count_metas() {
      var metas = 0;
      this.plan_objectives.forEach((objective) => {
        metas += objective.measures_total;
      });
      return metas;
    },
    count_actions_plans() {
      var actions_plans = 0;
      this.plan_objectives.forEach((objective) => {
        actions_plans += objective.plans_total;
      });
      return actions_plans;
    },
    count_actions_plans_itens() {
      var actions_plans = 0;
      this.plan_objectives.forEach((objective) => {
        actions_plans += objective.plans_itens_total;
      });
      return actions_plans;
    },
  },
  methods: {
    ...mapActions("strategic_plans", ["ActionShow", "ActionDelete"]),
    setTabSelect(value) {
      this.tabSelected = value;
      this.$emit("input", value);
    },
    deletePlan() {
      this.$bvModal
        .msgBoxConfirm("Essa operação não poderá ser desfeita.", {
          title: "Deseja realmente excluir?",
          okVariant: "danger",
          okTitle: "SIM",
          size: "md",
          buttonSize: "sm",
          cancelVariant: "outline-primary",
          cancelTitle: "NÃO",
          centered: true,
          headerClass: "border-bottom-0 pb-0",
        })
        .then(async (value) => {
          if (value) {
            await this.ActionDelete(this.strategic_plan.id);
            this.$router.push({ name: "planos_estrategicos_list" });
          }
        });
    },
  },
  async created() {
    if (this.$route.query.showObjectives) {
      this.setTabSelect("objectives");
    }
  },
};
</script>

<style>
.link-edit {
  align-self: flex-end;
}
.semafaro {
  position: absolute;
  right: 1rem;
  top: -1rem;
}
</style>
