<template>
  <div class="w-100 mb-5">
    <!-- <div v-if="1 > 1">
      <pre>{{ strategic_plan | pretty_json }}</pre>
    </div> -->

    <Header v-model="tab_view" />

    <b-card class="border-0 radius-top-0 mb-5" no-body v-if="show_card">
      <div class="p-4" v-if="tab_view == 'structure'">
        <Estrutura :structure="tree_plan_structure" />
      </div>
      <div class="p-4" v-if="tab_view == 'objectives'">
        <Objetivos :objectives="plan_objectives" />
      </div>
    </b-card>
  </div>
</template>

<script>
import { Objetivos } from "../components/Forms";
import Header from "../components/Header";
import Estrutura from "../components/Estrutura";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Header,
    Estrutura,
    Objetivos
  },
  data: () => ({
    tab_view: "structure"
  }),
  computed: {
    ...mapState("strategic_plans", [
      "strategic_plan",
      "plan_objectives",
      "tree_plan_structure"
    ]),
    show_card() {
      return this.strategic_plan && Array.isArray(this.tree_plan_structure);
    }
  },
  methods: {
    ...mapActions("strategic_plans", [
      "ActionShow",
      "ActionLoadPlanStructure",
      "ActionGetObjectives"
    ]),
    async loadData(id) {
      await this.ActionShow({ id: id });
      await Promise.all([
        this.ActionLoadPlanStructure(),
        this.ActionGetObjectives(id)
      ]);
    }
  },
  async created() {
    const id = this.$route.params.id;
    this.loadData(id);
  }
};
</script>

<style>
.semafaro {
  position: absolute;
  right: 2rem;
  top: -1rem;
}
</style>
