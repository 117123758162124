<template>
  <div>
    <div class="d-flex align-items-end">
      <span class="flex-grow-1">
        <div class="d-flex justify-content-between estrutura-header">
          <small>{{ level_name }}<br /></small>
          <b-button
            @click="delete_item()"
            size="sm"
            variant="light"
            class="mr-2 bt-action text-danger"
            v-if="show_delete"
          >
            <font-awesome-icon icon="trash" />
            Deletar Item
          </b-button>
        </div>
        <b-form-input v-model="value.name"></b-form-input>
      </span>

      <div
        v-b-toggle="'tree-objectives-' + value.id"
        class="add-inline-button mt-1 d-flex align-items-center"
        v-if="show_objectives"
      >
        <span class="material-icons">expand_more</span>
      </div>
      <div
        v-b-toggle="'tree-item-' + value.id"
        class="add-inline-button mt-1 d-flex align-items-center"
        v-if="!show_objectives"
      >
        <span class="material-icons">expand_more</span>
      </div>
    </div>

    <b-collapse
      v-if="show_children || true"
      :id="'tree-item-' + value.id"
      class="mt-2"
    >
      <b-card v-for="(item, key) in value.children" :key="key" class="mb-2">
        <EstruturaItem
          v-model="value.children[key]"
          :deep="next_deep"
          @delete="delete_child(key)"
        />
      </b-card>
      <div
        @click="add_item()"
        class="add-button mt-1 text-primary d-flex align-items-center"
        v-if="can_add_child"
      >
        <span class="material-icons mr-2">add</span> Adicionar
        {{ levels[deep + 1] }}
      </div>
    </b-collapse>

    <b-collapse
      :id="'tree-objectives-' + value.id"
      class="mt-2"
      v-if="show_objectives"
    >
      <EstruturaObjetivos :plan_structure_id="value.id" />
      <div
        @click="add_objective()"
        v-if="show_objectives"
        class="add-button mt-1 text-primary d-flex align-items-center"
      >
        <span class="material-icons mr-2">add</span> Novo Objetivo
      </div>
    </b-collapse>
  </div>
</template>

<script>
import EstruturaObjetivos from "./EstruturaObjetivos";
import _ from "lodash";
import { mapState, mapMutations } from "vuex";
import { ADD_PLAN_OBJECTIVE } from "@/modules/strategic_plans/store/mutation-types";

export default {
  name: "EstruturaItem",
  components: {
    EstruturaItem: () => import("./EstruturaItem"),
    EstruturaObjetivos,
  },
  props: {
    value: {
      type: Object,
    },
    deep: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({}),
  computed: {
    ...mapState("strategic_plans", [
      "strategic_plan",
      "tree_plan_structure",
      "plan_objectives",
    ]),
    objectives() {
      return _.filter(this.plan_objectives, [
        "plan_structure_id",
        this.value.id,
      ]);
    },
    show_children() {
      return this.value.children && this.value.children.length ? true : false;
    },
    levels() {
      return this.strategic_plan.config.plan_structure.levels;
    },
    level_name() {
      return this.levels[this.deep];
    },
    show_objectives() {
      return this.deep >= this.levels.length - 1;
    },
    can_add_child() {
      return this.deep < this.levels.length - 1;
    },
    next_deep() {
      return this.deep + 1;
    },
    show_delete() {
      let show = !this.show_children && !this.objectives.length;
      return show;
    },
  },
  methods: {
    ...mapMutations("strategic_plans", [ADD_PLAN_OBJECTIVE]),
    add_item() {
      if (!Array.isArray(this.value.children)) {
        this.value.children = [];
      }

      this.value.children.push({
        id: "uuid_" + Date.now(),
        strategic_plan_id: this.strategic_plan.id,
        parent_id: this.value.id,
        name: "",
      });
    },
    add_objective() {
      if (this.show_objectives)
        var objective = {
          plan_structure_id: this.value.id,
          name: "Novo Objetivo",
          description: "...",
          ods: [],
          indicators: [],
        };

      this[ADD_PLAN_OBJECTIVE](objective);

      this.$notifications.addNotification({
        message: "Objetivo poderá ser editado ao clicar em Salvar",
        type: "warning",
      });
    },
    delete_item() {
      if (!this.value.children || !this.value.children.length) {
        this.$emit("delete");
        this.$notifications.addNotification({
          message: "Item será removido ao clicar em Salvar",
          type: "warning",
        });
      } else {
        this.$notifications.addNotification({
          message: "Item não pode ser removido",
          type: "danger",
        });
      }
    },
    delete_child(index) {
      this.value.children.splice(index, 1);
    },
  },
};
</script>


<style lang="scss">
.estrutura-header {
  .bt-action {
    opacity: 0;
    transition: all 0.3s;

    &:first-of-type {
      margin-left: auto !important;
    }
  }

  &:hover {
    .bt-action {
      opacity: 1;
    }
  }
}

.add-inline-button {
  height: 38px;
  width: 38px;
  margin: 0;
  padding: 0;
  background: #f2f2f2;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  border-radius: 4px;
}
</style>
