<template>
  <div>
    <b-table striped hover :items="objectives" :fields="fields">
      <template #cell(ods)="row">
        <div class="text-nowrap">
          <b-img
            v-for="(ods, key) in row.item.ods"
            :key="key"
            :src="'https://odsbrasil.gov.br/content/ods/' + ods.id + '.png'"
            v-bind="mainProps"
            rounded
            :alt="ods.name"
          ></b-img>
        </div>
      </template>
      <template #cell(badges)="{ item }">
        <div class="d-flex flex-nowrap">
          <b-badge
            v-if="item.measures_total"
            class="mr-2 bg-measures-color"
            v-b-tooltip.hover
            title="Metas"
            >{{ item.measures_total }}</b-badge
          >

          <b-badge
            v-if="item.indicators_total"
            class="mr-2 bg-indicators-color"
            v-b-tooltip.hover
            title="Indicadores"
            >{{ item.indicators_total }}</b-badge
          >

          <b-badge
            v-if="item.plans_total"
            class="mr-2 bg-warning text-dark"
            v-b-tooltip.hover
            title="Planos de ações"
          >
            {{ item.plans_total }}
          </b-badge>
        </div>
      </template>
      <template #cell(actions)="row">
        <Actions
          v-if="row.item.id"
          :item="row.item"
          :view_show="$can('objective:view')"
          :view_edit="$can('objective:edit')"
          :view_delete="$can('objective:delete')"
          view_to="objectives_show"
          edit_to="objectives_edit"
          @delete="delete_objetive"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import Actions from "@/components/Table/Actions";
import { mapActions } from "vuex";

export default {
  components: { Actions },
  props: {
    objectives: {
      type: Array,
    },
  },
  data: () => ({
    mainProps: { width: 30, height: 30, class: "ml-1" },
  }),
  computed: {
    fields() {
      return [
        { key: "id", label: "#" },
        { key: "name", label: "Objetivo", class: "col-10" },
        //{ key: "description", label: "Descrição" },
        { key: "badges", label: "" },
        { key: "actions", label: "#" },
      ];
    },
  },
  methods: {
    ...mapActions("objectives", ["ActionDelete"]),
    ...mapActions("strategic_plans", ["ActionGetObjectives"]),
    async delete_objetive(objective) {
      let strategic_plan_id = objective.strategic_plan.id;
      await this.ActionDelete(objective);
      await this.ActionGetObjectives(strategic_plan_id);
    },
  },
};
</script>

<style></style>
