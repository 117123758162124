<template>
  <div>
    <ObjetivosTable :objectives="plan_objectives" />
  </div>
</template>

<script>
import ObjetivosTable from "./ObjetivosTable";

import { mapState } from "vuex";

export default {
  components: {
    ObjetivosTable
  },
  data: () => ({}),
  computed: {
    ...mapState("strategic_plans", ["strategic_plan", "plan_objectives"])
  },
  methods: {}
};
</script>

<style></style>
