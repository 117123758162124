<template>
  <div class="traffic-light">
    <div
      class="light red"
      :class="getActivatedClass('red')"
      :style="formatedSizeLight"
    ></div>
    <div
      class="light yellow"
      :class="getActivatedClass('yellow')"
      :style="formatedSizeLight"
    ></div>
    <div
      class="light green"
      :class="getActivatedClass('green')"
      :style="formatedSizeLight"
    ></div>
  </div>
</template>
<script>
export default {
  name: "TraffigLight",
  props: {
    status: {
      type: String,
      default: "",
      validator: value => ["red", "yellow", "green", ""].includes(value)
    },
    lightSize: {
      type: String,
      default: ""
    }
  },
  computed: {
    formatedSizeLight() {
      const size = this.lightSize;
      return size ? { width: size, height: size } : "";
    }
  },
  methods: {
    getActivatedClass(statusColor) {
      const isActiveColor = this.status === statusColor;
      return [{ activated: isActiveColor }];
    }
  }
};
</script>
<style scoped lang="scss">
.traffic-light {
  width: 70px;
  background: #222;
  background-image: linear-gradient(
    transparent 2%,
    #111 2%,
    transparent 3%,
    #111 30%
  );
  border: solid 4px #333;
  border-radius: 20px;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .light {
    width: 36px;
    height: 36px;
    margin-bottom: 8px;
    position: relative;
    border-radius: 20px;
    opacity: 0.2;
  }
  .red {
    background: #fe2344;
    background-image: radial-gradient(#b32828, transparent);
    background-size: 3px 3px;
    border: dotted 1px #fe2344;
    box-shadow: 0 0 10px #3e3e3e inset, 0 0 10px #fe2344;
  }
  .yellow {
    background: #ffe018;
    background-image: radial-gradient(#ffc415, transparent);
    background-size: 3px 3px;
    border: dotted 1px #ffe018;
    box-shadow: 0 0 10px #3e3e3e inset, 0 0 10px #ffe018;
  }
  .green {
    background: #01ad32;
    background-image: radial-gradient(#18f361, transparent);
    background-size: 3px 3px;
    border: dotted 1px #01ad32;
    box-shadow: 0 0 10px #3e3e3e inset, 0 0 10px #01ad32;
  }

  .activated {
    opacity: 1 !important;
  }
}
</style>
