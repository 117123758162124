<template>
  <div>
    <b-card v-if="!strategic_plan.id" class="mb-2">
      <b-form-checkbox v-model="strategic_plan.initial_bsc_structure" switch>
        Iniciar com a Estrutura BSC
      </b-form-checkbox>
    </b-card>

    <div v-if="!strategic_plan.initial_bsc_structure">
      <div class="d-flex flex-row-reverse mb-4">
        <b-button
          variant="outline-primary"
          size="sm"
          @click="add_estrutura_item()"
        >
          <span class="label"
            >Adicionar {{ strategic_plan.config.plan_structure.levels[0] }}
            <font-awesome-icon icon="plus" />
          </span>
        </b-button>
      </div>

      <b-card
        v-for="(item, key) in tree_plan_structure"
        :key="key"
        class="mb-2 tree-item-edit"
      >
        <EstruturaItem
          v-model="tree_plan_structure[key]"
          @delete="delete_item(key)"
          :deep="deep"
        />
      </b-card>
    </div>
  </div>
</template>

<script>
import EstruturaItem from "./EstruturaItem";
import { mapState } from "vuex";

export default {
  name: "Estrutura",
  components: { EstruturaItem },
  props: {
    value: {
      type: Array,
    },
    deep: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({}),
  computed: {
    ...mapState("strategic_plans", ["strategic_plan", "tree_plan_structure"]),
  },
  methods: {
    add_estrutura_item() {
      this.tree_plan_structure.push({
        parent_id: null,
        name: "...",
        children: [],
      });
    },
    delete_item(index) {
      this.tree_plan_structure.splice(index, 1);
    },
  },
};
</script>

<style>
.tree-item-edit {
  background: rgba(0, 0, 0, 0.1) !important;
}
</style>
