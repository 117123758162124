<template>
  <div>
    <b-form-group label="Número de Níveis:" v-if="!has_objectives">
      <b-form-spinbutton
        inline
        v-model="num_levels"
        min="1"
        max="5"
        :disabled="has_objectives"
      ></b-form-spinbutton>
    </b-form-group>

    <b-form-group label="Estrutura do Plano:">
      <b-form-input
        v-for="(level, key) in levels"
        :key="key"
        v-model="levels[key]"
        :placeholder="'Nível ' + (key + 1)"
        required
        class="mb-2"
      ></b-form-input>
    </b-form-group>

    <b-form-group label="Status do Plano:">
      <b-form-radio
        v-model="strategic_plan.config.plan_structure.status"
        name="plan-status"
        value="green"
        >Verde</b-form-radio
      >
      <b-form-radio
        v-model="strategic_plan.config.plan_structure.status"
        name="plan-status"
        value="yellow"
        >Amarelo</b-form-radio
      >
      <b-form-radio
        v-model="strategic_plan.config.plan_structure.status"
        name="plan-status"
        value="red"
        >Vermelho</b-form-radio
      >
    </b-form-group>

    <b-form-group label="Ícone do Plano:">
      <b-input-group>
        <template #prepend v-if="strategic_plan.icon">
          <b-input-group-text>
            <font-awesome-icon :icon="plan_icon" />
          </b-input-group-text>
        </template>
        <b-form-input v-model="strategic_plan.icon"></b-form-input>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},
  props: {},
  data: () => ({ num_levels: 0, levels: ["Perspectiva", "Diretriz"] }),
  computed: {
    ...mapState("strategic_plans", ["strategic_plan", "plan_objectives"]),
    plan_icon() {
      if (this.strategic_plan.icon) {
        var icon = this.strategic_plan.icon.split(" ");
        if (icon.length > 1) {
          return icon;
        } else {
          return this.strategic_plan.icon;
        }
      }
      return false;
    },
    has_objectives() {
      return (this.plan_objectives.length > 0);
    },
  },
  methods: {},
  watch: {
    num_levels: function (value) {
      while (value < this.levels.length) {
        this.strategic_plan.config.plan_structure.levels.pop();
      }

      while (value > this.levels.length) {
        this.strategic_plan.config.plan_structure.levels.push("");
      }
    },
  },
  created() {
    if (Array.isArray(this.strategic_plan.config.plan_structure.levels)) {
      this.levels = this.strategic_plan.config.plan_structure.levels;
    }
    this.num_levels = this.levels.length;

    if (!this.strategic_plan.config.plan_structure.status) {
      this.strategic_plan.config.plan_structure.status = "green";
    }
  },
};
</script>

<style>
</style>
