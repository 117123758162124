<template>
  <div>
    <ObjetivosTable :objectives="objectives" v-if="objectives.length" />
    <div v-else>
      <b-alert show variant="warning" class="text-center"
        >Ainda não foram cadastrados objetivos</b-alert
      >
    </div>
  </div>
</template>

<script>
import ObjetivosTable from "./ObjetivosTable";
import _ from "lodash";
import { mapState } from "vuex";

export default {
  components: { ObjetivosTable },
  props: {
    plan_structure_id: {
      required: true,
    },
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapState("strategic_plans", ["plan_objectives"]),
    objectives() {
      return _.filter(this.plan_objectives, [
        "plan_structure_id",
        this.plan_structure_id,
      ]);
    },
  },
  created() {},
};
</script>

<style>
</style>